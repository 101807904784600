import { LiquidityProvider, Quote, PegoutQuote, FlyoverUtils } from '@rsksmart/flyover-sdk';

export function assertIsAccount(
  account: string | undefined,
): asserts account is string {
  if (!account) throw new Error('Web3 provider is not connected');
}
export function assertIsLp(
  lp: LiquidityProvider | undefined,
): asserts lp is LiquidityProvider {
  if (!lp) throw new Error('Liquidity provided is not selected');
}
export function assertIsQuote(
  quote: Quote | PegoutQuote | undefined,
): asserts quote is Quote | PegoutQuote {
  if (!quote) throw new Error('Cannot get quotes');
}

export function assertIsValidBtcAddress(address: string) {
  if (!address) {
      return false;
  }
  return FlyoverUtils.isBtcAddress(address)
}
